export const LOGIN_TOKEN_KEY = 'poker-admin-token';

export const API_URLS = {
  //基础接口
  authLoginCaptcha: '/admin/auth/loginCaptcha',
  autoInfo: '/admin/auth/info',
  authLogin: '/admin/auth/login',

  //统计
  statisticIndex: '/admin/statistic/index',

  //后台管理
  admUserLists: '/admin/admUser/lists',
  admUserStore: '/admin/admUser/store',

  //系统配置
  systemConfigLists: '/admin/system/config/lists',
  systemConfigStore: '/admin/system/config/store',
  systemConfigItemIdSet: '/admin/system/config/itemIdSet',
  systemAppVersionLists: '/admin/system/appVersion/lists',
  systemAppVersionStore: '/admin/system/appVersion/store',
  systemAppVersionForceUpdateVersionPush:
    '/admin/system/appVersion/forceUpdateVersionPush',
  systemAppBulletinLists: '/admin/system/appBulletin/lists',
  systemAppBulletinStore: '/admin/system/appBulletin/store',
  systemAppConfigLists: '/admin/systemAppConfig/lists',
  systemAppConfigStore: '/admin/systemAppConfig/store',
  systemInnerDeviceLists: '/admin/systemInnerDevice/lists',
  systemInnerDeviceAdd: '/admin/systemInnerDevice/add',
  systemInnerDeviceDelete: '/admin/systemInnerDevice/delete',

  //用户管理
  userLists: '/admin/user/lists',
  userActiveHistory: '/admin/user/activeHistory', //接口暂停使用
  userAllProps: '/admin/user/allProps',
  userSendProps: '/admin/user/sendProps',
  userLimitedAccessList: '/admin/user/limitedAccessList',
  userUpdateLimitedAccess: '/admin/user/updateLimitedAccess',

  //订单管理
  rechargeOrderLists: '/admin/rechargeOrder/lists',
  rechargeOrderLog: '/admin/rechargeOrder/log',

  //本地化运营
  localOperationPromotionCodeLists: '/admin/localOperation/promotionCode/lists',
  localOperationPromotionCodeStore: '/admin/localOperation/promotionCode/store',
  localOperationPromotionCodeDelete:
    '/admin/localOperation/promotionCode/delete',
  localOperationNewcomerAgentLists: '/admin/localOperation/newcomerAgent/lists',
  localOperationNewcomerAgentStore: '/admin/localOperation/newcomerAgent/store',
  localOperationNewcomerAgentDelete:
    '/admin/localOperation/newcomerAgent/delete',
  localOperationNewcomerWithdrawLists:
    '/admin/localOperation/newcomerWithdraw/lists',
  localOperationNewcomerWithdrawReview:
    '/admin/localOperation/newcomerWithdraw/review',
  localOperationNewcomerWithdrawLog: '/admin/localOperation/newcomerWithdraw/log',

  //运营活动
  activityLists: '/admin/activity/lists',
  activityDetail: '/admin/activity/detail',
  activityStore: '/admin/activity/store',

  //俱乐部
  clubPromoterLists: '/admin/club/promoterLists',
  clubReviewPromoter: '/admin/club/reviewPromoter',
  clubLists: '/admin/club/lists',

  //工具
  toolsGameLogLists: '/admin/gameLog/lists',
  toolsGameLogDetail: '/admin/gameLog/detail',

  //message
  messageSendEmail: '/admin/message/sendEmail',
  messageSendNotice: '/admin/message/sendNotice',

  //聊天
  supportingList: '/admin/support/supportingList',
  supportSend: '/admin/support/send',
  supportDelete: '/admin/support/delete',
  supportCreate: '/admin/support/create',
};
