export default class<K, V> extends Map {
  constructor(entries?: readonly (readonly [K, V])[] | null) {
    super();
    entries?.map((item) => {
      this.set(item[0], item[1])
    })
  }

  getWithDefault(key: K, defaultValue: V = null as any): V {
    const v = this.get(key)
    if (v === undefined) {
      return defaultValue
    }
    return v
  }

  map<U>(callbackfn: (value: V, ket: K) => U): U[] {
    const res: U[] = [];
    this.forEach((v, k) => {
      res.push(callbackfn(v, k));
    })
    return res;
  }
}

