import './App.scss';
import { Component, PropsWithChildren } from 'react';
import { Button, Form, Input } from 'antd';
import Fetch, { isProd } from './utils/fetch';
import { API_URLS } from '@/constants/url';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Layout from '@/components/layout/index';
import Loadable from 'react-loadable';
import LazyLoading from '@/components/lazy_loading';
import RouteWithRole from '@/pages/routes';
import NotFound from '@/pages/not-found';
import User from '@/utils/user';

interface ILoginBox {
  captchaBase64: string;
  captchaKey: string;
  errMsg: string;
}

interface IState {
  isLoading: boolean;
  loginBox: ILoginBox;
  user?: User;
}

class App extends Component<PropsWithChildren, IState> {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      loginBox: {} as ILoginBox,
    };
  }

  componentDidMount() {
    User.ready()
      .then((user) => {
        this.setState({ isLoading: false, user });
      })
      .catch(() => {
        User.logout();
        this.getCaptcha();
      });
  }

  getCaptcha = () => {
    //加载图片验证码
    if (isProd) {
      Fetch.get(API_URLS.authLoginCaptcha)
        .then((data) => {
          const { loginBox } = this.state;
          loginBox.captchaBase64 = data.base64Content;
          loginBox.captchaKey = data.captchaKey;
          this.setState({
            isLoading: false,
            loginBox: loginBox,
          });
        })
        .catch(() => {});
    } else {
      this.setState({
        isLoading: false,
      });
    }
  };

  login = (values) => {
    let captchaKey = 'test',
      captcha = 'test';
    if (isProd) {
      captchaKey = this.state.loginBox.captchaKey;
      captcha = values.captcha;
    }
    User.login(values.username, values.password, captchaKey, captcha)
      .then((user) => {
        this.setState({ user });
      })
      .catch((err) => {
        const { loginBox } = this.state;
        loginBox.errMsg = err;
        this.setState({ loginBox });
        this.getCaptcha();
      });
  };

  logout = () => {
    User.logout();
    this.setState({ user: undefined });
  };

  render() {
    const { isLoading, user, loginBox } = this.state;
    if (isLoading) {
      return;
    }
    if (user === undefined) {
      return (
        <div className='login'>
          <h1>Poker后台管理系统</h1>
          <Form
            name='basic'
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 19 }}
            onFinish={this.login}>
            <Form.Item
              label='用户名'
              name='username'
              rules={[{ required: true, message: '请输入用户名!' }]}>
              <Input size='large' />
            </Form.Item>

            <Form.Item
              label='密码'
              name='password'
              rules={[{ required: true, message: '请输入密码!' }]}>
              <Input.Password size='large' />
            </Form.Item>

            {isProd ? (
              <Form.Item
                label='验证码'
                name='captcha'
                rules={[{ required: true, message: '请输入验证码!' }]}>
                <Input
                  size='large'
                  addonAfter={
                    <img
                      alt=''
                      src={loginBox.captchaBase64}
                      onClick={this.getCaptcha}
                    />
                  }
                />
              </Form.Item>
            ) : null}

            <p className='errorMsg'>{loginBox.errMsg}</p>

            <Form.Item wrapperCol={{ offset: 10, span: 16 }}>
              <Button type='primary' htmlType='submit'>
                登陆
              </Button>
            </Form.Item>
          </Form>
        </div>
      );
    }

    const routeWithRole = new RouteWithRole(user);
    return (
      <BrowserRouter>
        <Routes>
          <Route element={<Layout user={user} logout={this.logout} />}>
            {routeWithRole.routesMapValues().map((item) => {
              if (item.tsxPath === undefined) {
                return '';
              }
              const Element = Loadable({
                loader: () => import(`@/pages/${item.tsxPath}`),
                loading: LazyLoading,
              });
              return (
                <Route
                  key={item.id}
                  path={item.path}
                  element={<Element routeId={item.id} user={user} />}
                />
              );
            })}
            <Route path='*' element={<NotFound />} />
          </Route>
        </Routes>
      </BrowserRouter>
    );
  }
}

export default App;
