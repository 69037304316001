import Fetch from "@/utils/fetch";
import { API_URLS } from "@/constants/url";
import { LOGIN_TOKEN_KEY } from "@/constants/common";
import { PropsWithChildren } from "react";
import lodash from 'lodash';

interface IUser {
  uid: number;
  username: string,
  ruleId: number,
}

export interface PropsWithUser extends PropsWithChildren {
  user: User,
}

export enum rule {
  superAdmin = 1,
  admin,
  operations,//运营
  customerService,//客服
}

export function renderRule(ruleId: number): string[] {
  switch (ruleId) {
    case rule.superAdmin:
      return ['magenta', "超级管理员"];
    case rule.admin:
      return ['volcano', "管理员"];
    case rule.operations:
      return ['gold', "运营"];
    case rule.customerService:
      return ['green', "客服"];
    default:
      return ['red', "未定义"];
  }
}

export default class User implements IUser {
  protected static promise: Promise<User>;

  public static ready(): Promise<User> {
    if (User.promise) {
      return User.promise
    }
    return User.promise = new Promise<User>((resolve, reject) => {
      const token = window.localStorage.getItem(LOGIN_TOKEN_KEY)
      if (!token) {
        User.promise = null as any;
        reject("token不存在");
        return;
      }

      Fetch.get(API_URLS.autoInfo).then(data => {
        const u = new User(data.uid, data.username, data.ruleId);
        resolve(u);
      }).catch((e) => {
        User.promise = null as any;
        console.log(e);
        reject("获取用户信息失败");
        return;
      })
    })
  }

  public static login(username: string, password: string, captchaKey: string, captcha: string): Promise<User> {
    return User.promise = new Promise<User>((resolve, reject) => {
      Fetch.post(API_URLS.authLogin, { username, password, captchaKey, captcha }).then((data) => {
        window.localStorage.setItem(LOGIN_TOKEN_KEY, data.token)
        const u = new User(data.user.uid, data.user.username, data.user.ruleId);
        resolve(u);
      }).catch(err => {
        reject(err.msg);
      })
    });
  }

  public static logout() {
    window.localStorage.removeItem(LOGIN_TOKEN_KEY)
    User.promise = null as any;
  }

  public readonly uid: number
  public readonly username: string
  public readonly ruleId: number;

  constructor(uid: number, username: string, ruleId: number) {
    this.uid = uid
    this.username = username
    this.ruleId = ruleId
  }

  hasHomeStatisticPermissions(): boolean {//是否可以查看首页的统计数据
    return lodash.includes([rule.superAdmin, rule.admin], this.ruleId)
  }

  hasSendPropsPermissions(): boolean {//是否有发送道具的权限
    return lodash.includes([rule.superAdmin, rule.admin], this.ruleId)
  }
}
