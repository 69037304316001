import Map from "@/utils/map";

export const LOGIN_TOKEN_KEY = "poker-admin-token"

/**
 * 系统-苹果
 */
export const osIphone = 1;

/**
 * 系统-安卓
 */
export const osAndroid = 2;

export const osMap = new Map<number, string>([
  [osIphone, "苹果"],
  [osAndroid, "安卓"],
])

export const detailTypeCreate = 1;
export const detailTypeEdit = 2;
export const detailTypeClone = 3;


/**
 * 登录方式-游客
 */
export const loginTypeGuest = 0;

/**
 * 登录方式-Facebook
 */
export const loginTypeFacebook = 1;

/**
 * 登录方式-Apple
 */
export const loginTypeApple = 2;

/**
 * 登录方式-Google
 */
export const loginTypeGoogle = 3;

export const loginTypeMap = new Map<number, string>([
  [loginTypeGuest, "游客"],
  [loginTypeFacebook, "Facebook"],
  [loginTypeApple, "Apple"],
  [loginTypeGoogle, "Google"],
])
