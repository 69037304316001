import { Outlet, useNavigate, useLocation, matchRoutes } from "react-router-dom";
import { Button, Layout, Menu, Watermark } from "antd";
import "./index.scss";
import RouteWithRole, { IRoute } from "@/pages/routes";
import { useEffect, useState } from "react";
import User from "@/utils/user";


function getItem(label: string, key: string, icon?: null | undefined, children?: { key: any; icon: any; children: any; label: any; type: any; }[] | undefined, type?: undefined) {
  return { key, icon, children, label, type };
}

function items(routes: IRoute[]) {
  return routes.map(item => {
    //有渲染的组件或者没有子集
    if (item.tsxPath !== undefined || item.childRoutes === undefined) {
      return getItem(item.label, item.id);
    }
    return getItem(item.label, item.id, null, items(item.childRoutes))
  });
}

export default function LayoutComponent(props: { user: User, logout: () => void }) {
  const navigate = useNavigate();
  const { user, logout } = props;
  const location = useLocation()

  const [selectedKeys, setSelectedKeys] = useState([] as string[]);
  const [openKeys, setOpenKeys] = useState([] as string[]);
  const [headerTitle, setHeaderTitle] = useState("");

  useEffect(() => {
    const tmp = matchRoutes((new RouteWithRole(user)).routesMapValues(), location);
    if (tmp != null) {
      const [{ route }] = tmp
      setSelectAndOpen(route);
    }
  }, [])

  const routeWithRole = new RouteWithRole(user);


  const setSelectAndOpen = (route: IRoute) => {
    const { id, parentId, label } = route
    setHeaderTitle(label)
    if (parentId === undefined) {
      setSelectedKeys([id])
      setOpenKeys([])
    } else {
      let tmpRouteId = id, selectedId = "", openId = ""//选中的是二级，openId是一级
      while (true) {
        const current = routeWithRole.routesMap()[tmpRouteId]
        if (current.parentId === undefined) {
          break;
        }
        const parent = routeWithRole.routesMap()[current.parentId]

        selectedId = current.id
        openId = parent.id

        tmpRouteId = parent.id
      }
      setSelectedKeys([selectedId])
      setOpenKeys([openId])
    }
  }

  return (
    <Watermark content={user.username}>
      <Layout className="layout">
        <Layout.Sider breakpoint="sm">
          <div>
            <div className="menu-header">Poker-Admin</div>
            <Menu mode="inline" theme="dark" selectedKeys={selectedKeys} openKeys={openKeys}
                  items={items(routeWithRole.routes())}
                  onClick={(e) => {
                    if (routeWithRole.routesMap()[e.key].path) {
                      navigate(routeWithRole.routesMap()[e.key].path as "")
                      setSelectAndOpen(routeWithRole.routesMap()[e.key]);
                    }
                  }}
                  onOpenChange={setOpenKeys}
            />
          </div>
        </Layout.Sider>
        <Layout>
          <Layout.Header className="header">
            <header>
              <div className="title">{`Poker后台管理系统-${headerTitle}`}</div>
              <span className="username">{user.username}</span>
              <Button onClick={logout}>登出</Button>
            </header>
          </Layout.Header>
          <Layout.Content className={`main-view page-${selectedKeys[0]}`}>
            <Outlet/>
          </Layout.Content>
        </Layout>
      </Layout>
    </Watermark>
  )
}
